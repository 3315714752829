import React, { Fragment, useRef, useState, useContext, useEffect } from "react"
import FormInput from "../Elements/Form/FormInput"
import FormSelect from "../Elements/Form/FormSelect"
import beneficiaries from "./utils/beneficiaryTypes.json"
import Dropzone from "../Upload/Dropzone"
import UploadErrorNotification from "../Upload/Dropzone/UploadErrorNotification"
import RequiredBeneficiaryDocuments from "../Mechanics/RequiredBeneficiaryDocuments"
import CartLayout from "../Layout/CartLayout"
import FlexMedCart from "../FlexMed/Cart"

import { isIeOrEdge } from "../../services/browserCompatibility"

import { AppContext } from "../../context/AppContext"
import {
  uploadDocument,
  checkIfUploadDisabled,
} from "../Upload/services/uploadDocuments"

const MAX_FILE_SIZE_IN_BYTES = 5242880
const MAX_FILE_COUNT = 6

const BeneficiaryDetails = ({ values, setFieldValue, location }) => {
  const handleOnChange = values => {
    setFieldValue("beneficiaryRelationship", values)
    if (
      values?.beneficiaryRelationship?.value !=
      "Domestic partner or common law spouse"
    ) {
      dispatch({
        type: "SAVE_BENEFICIARY_DOCUMENTS",
        payload: [],
      })
    }
  }
  const fileInputRef = useRef(null)
  const [notifications, setNotifications] = useState([])
  const { dispatch, state } = useContext(AppContext)
  const [filesUploaded, setFilesUploaded] = useState(
    state.flexmed.beneficiaryDocuments
  )

  const allFilesUploaded = [
    ...state.flexmed.beneficiaryDocuments,
    ...state.flexmed.documents,
  ]
  const handleFileChooser = () => {
    fileInputRef.current.click()
  }

  const openFileChooser = () => {
    if (isIeOrEdge()) setTimeout(handleFileChooser, 0)
    else handleFileChooser()
  }

  const handleAlreadyUploaded = setNotifications => file => {
    setNotifications(notificationsList => {
      let previousNotifications = [...notificationsList]
      previousNotifications.push(
        <UploadErrorNotification
          fileName={file.oldname}
          message="has already been uploaded."
        />
      )
      return previousNotifications
    })
  }

  const closeNotifications = () => {
    setNotifications([])
  }

  const handleFileRead = event => {
    closeNotifications()
    const tempFilesUploaded = [...event.target.files]
    const initialNotifications = []
    if (tempFilesUploaded.length + filesUploaded.length > MAX_FILE_COUNT)
      initialNotifications.push(
        <UploadErrorNotification
          message={`Please upload only a maximum of ${MAX_FILE_COUNT} files.`}
        />
      )
    else
      for (let i = 0; i < tempFilesUploaded.length; i++) {
        if (tempFilesUploaded[i].size < MAX_FILE_SIZE_IN_BYTES) {
          uploadDocument(
            tempFilesUploaded[i],
            filesUploaded,
            setFilesUploaded,
            prevFilesUploaded => {
              dispatch({
                type: "SAVE_BENEFICIARY_DOCUMENTS",
                payload: [...prevFilesUploaded],
              })
            },
            handleAlreadyUploaded(setNotifications),
            "docs",
            allFilesUploaded
          )
        } else
          initialNotifications.push(
            <UploadErrorNotification
              fileName={tempFilesUploaded[i].name}
              message="is greater than 5MB. Please upload a file or photo less than 5MB."
            />
          )
      }
    setNotifications(initialNotifications)
  }

  useEffect(() => {
    if (state.flexmed.beneficiaryDocuments.length < filesUploaded.length) {
      setFilesUploaded(state.flexmed.beneficiaryDocuments)
    }
  }, [state])

  return (
    <Fragment>
      <FormInput
        name="beneficiaryFirstName"
        value={values.beneficiaryFirstName}
        label="Beneficiary First Name"
        placeholder="Juana"
        isRequired
      />
      <FormInput
        name="beneficiaryLastName"
        label="Beneficiary Last Name"
        value={values.beneficiaryLastName}
        placeholder="Dela Cruz"
        isRequired
      />
      <FormSelect
        name="beneficiaryRelationship"
        label="Relationship"
        value={values.beneficiaryRelationship}
        placeholder="Parent"
        onChange={handleOnChange}
        options={beneficiaries}
        isRequired
      />

      {values?.beneficiaryRelationship?.value ===
        "Domestic partner or common law spouse" && (
        <Fragment>
          <RequiredBeneficiaryDocuments orderingFor={values.orderingFor} />
          <FlexMedCart
            open={{ documentsCard: true }}
            location={location}
            isFullSize={false}
          />
          <Dropzone
            fileInputRef={fileInputRef}
            handleFileChooser={openFileChooser}
            filesUploaded={filesUploaded}
            handleFileRead={handleFileRead}
            notifications={notifications}
          />
        </Fragment>
      )}
    </Fragment>
  )
}
export default BeneficiaryDetails
