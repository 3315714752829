import React from "react"
import classNames from "classnames"
import Img from "gatsby-image"

import styles from "../utils/elements.module.scss"

const FormStyledRadio = ({ isActive, onClick, label, logo, children }) => {
  return (
    <div
      className={classNames(
        "py-1",
        styles[`styledRadio__option${isActive ? "Active" : ""}`]
      )}
      onClick={onClick}
    >
      <div
        className={classNames("is-flex", styles["styledRadio__radioContainer"])}
      >
        <div className={classNames(styles["styledRadio__radioButton"])}>
          {isActive && (
            <div
              className={classNames(styles["styledRadio__radioButtonChecked"])}
            ></div>
          )}
        </div>
        <div
          className={classNames(
            "pr-1 has-text-centered",
            styles["styledRadio__label"]
          )}
        >
          {logo && <Img fixed={logo} />}
          <div>
            {label && <p className="is-size-5 has-text-weight-bold">{label}</p>}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormStyledRadio
