import React from "react"
import documentRequirements from "./utils/uploadBeneficiaryRequirements.json"

const RequiredBeneficiaryDocuments = ({ orderingFor }) =>
  orderingFor === "Dependent" && (
    <div className="notification is-light mt-1">
      <div className="content">
        For domestic partner or common law spouse, please also upload the
        following documents to help us process your order:
        <ul className="mt-1">
          {documentRequirements.map(item => (
            <li>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  )

export default RequiredBeneficiaryDocuments
