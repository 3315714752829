import React, { Fragment } from "react"
import { Field, FieldArray, ErrorMessage } from "formik"

/**
 ** Checkbox group linked in formik.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': String,
 **               'values': Object [],
 **               'label': String,
 **               'options': Object [],
 **               'onChange': Function,
 **               ...props compatible in Field Component of Formik
 **             }
 **/
const FormCheckbox = ({ name, options, values, onChange, title }) => {
  // Custom handleChange of every checkbox in the group
  //
  // Note: Curried function: need the form from the Field and option (text from values)
  //       to return then (event) => { ... }
  const handleChange = (form, option) => event => {
    const { setFieldValue } = form
    let newValue = [...values]
    if (event.target.checked) newValue.push(option)
    else newValue = newValue.filter(element => element !== option)
    setFieldValue(name, newValue)

    if (onChange) onChange(event)
  }

  const CheckBox = ({ form, option, index }) => (
    <label className="checkbox-label">
      <input
        type="checkbox"
        checked={values.find(element => element === option)}
        onChange={handleChange(form, option)}
        name={`${name}[${index}]`}
      />
      <span className="ml-1">{option}</span>
    </label>
  )

  return (
    <Fragment>
      {title && (
        <label className="label has-text-weight-bold">
          {title} <span className="has-text-danger">*</span>
        </label>
      )}
      <FieldArray name={name}>
        {() =>
          options.map((option, index) => (
            <div key={index} className="mb-1">
              <Field>
                {({ form }) => (
                  <CheckBox form={form} option={option} index={index} />
                )}
              </Field>
            </div>
          ))
        }
      </FieldArray>
      <p className="help is-danger mt-0 mb-1">
        <ErrorMessage name={name} />
      </p>
    </Fragment>
  )
}

export default FormCheckbox
