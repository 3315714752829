import { useStaticQuery, graphql } from "gatsby"

export const useHookImages = () => {
  const data = useStaticQuery(graphql`
    {
      family: file(
        relativePath: {
          eq: "mechanics/mechanics__immediate-family--colored.png"
        }
      ) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      individual: file(
        relativePath: { eq: "mechanics/mechanics__individual--colored.png" }
      ) {
        childImageSharp {
          fixed(width: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}
