import * as Yup from "yup"

export const mechanicsValidationSchema = Yup.object().shape({
  agreeToMechanics: Yup.array().min(1, "This field is required"),
  orderingFor: Yup.string()
    .trim()
    .required("This field is required"),
  beneficiaryFirstName: Yup.string()
    .trim()
    .when("orderingFor", {
      is: orderingFor => orderingFor === "Dependent",
      then: Yup.string().required("This field is required"),
    }),
  beneficiaryLastName: Yup.string()
    .trim()
    .when("orderingFor", {
      is: orderingFor => orderingFor === "Dependent",
      then: Yup.string().required("This field is required"),
    }),
  beneficiaryRelationship: Yup.string()
    .trim()
    .when("orderingFor", {
      is: orderingFor => orderingFor === "Dependent",
      then: Yup.string().required("This field is required"),
    }),
})
