import React, { Fragment } from "react"
import classNames from "classnames"
import FlexmedMechanicsTable from "../FlexMed/FlexmedMechanicsTable"
import mechanics from "./utils/mechanics.json"
import styles from "./utils/mechanics.module.scss"

const ProgramMechanics = ({ flow }) => {
  return (
    <div className={classNames("message-body", styles["collapsible"])}>
      <ol>
        {mechanics[0][flow].map((mechanics, index) => (
          <Fragment>
            <li key={index} dangerouslySetInnerHTML={{ __html: mechanics }} />
            {flow === "flexmed" && index === 4 && <FlexmedMechanicsTable />}
          </Fragment>
        ))}
      </ol>
    </div>
  )
}

export default ProgramMechanics
