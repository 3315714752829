import React from "react"
import Layout from "../../components/Layout/Layout"
import Mechanics from "../../components/Mechanics/Mechanics"

export default ({ location }) => {
  return (
    <Layout
      title="Mechanics"
      flow="flexmed"
      seoTitle="Flexmed Mechanics"
      subtitle="Please read in full and get started"
    >
      <Mechanics
        flow="flexmed"
        nextRoute="/flexmed/benefit-type"
        location={location}
      />
    </Layout>
  )
}
