import React, { useContext, Fragment } from "react"
import { Formik, Form } from "formik"
import classNames from "classnames"
import Container from "../Layout/Container"
import FormRadio from "../Elements/Form/FormRadio"
import FormCheckbox from "../Elements/Form/FormCheckbox"
import Section from "../Elements/Section"
import ActionButtons from "../Elements/ActionButtons"
import { AppContext } from "../../context/AppContext"
import FormStyledRadio from "../Elements/Form/FormStyledRadio"
import ProgramMechanics from "./ProgramMechanics"
import { mechanicsValidationSchema } from "./utils/formData"
import styles from "./utils/mechanics.module.scss"
import { useHookImages } from "./hooks/useHookImages"

import { navigate } from "gatsby"

import BeneficiaryDetails from "../PersonalDetails/BeneficiaryDetails"

let agreeToMechanicsBlurb = "I have read and I agree to the program mechanics."

const Mechanics = ({ flow, nextRoute, location }) => {
  const { state, dispatch } = useContext(AppContext)
  const DATA = useHookImages()

  let options = [
    {
      label: "Myself",
      logo: "individual",
    },
    {
      label: "Dependent",
      logo: "family",
    },
  ]

  const isDisabled = values => {
    if (
      values?.beneficiaryRelationship?.value !==
        "Domestic partner or common law spouse" ||
      values?.orderingFor === "Myself"
    ) {
      return !values?.agreeToMechanics?.length || !values?.orderingFor?.length
    } else {
      return (
        !values?.agreeToMechanics?.length ||
        !values?.orderingFor?.length ||
        state?.flexmed?.beneficiaryDocuments?.length < 1
      )
    }
  }
  const handleOnSubmit = async values => {
    await dispatch({
      type: "SAVE_ORDERING_FOR",
      payload: values.orderingFor,
    })
    await dispatch({
      type: `SAVE_AGREE_${flow.toUpperCase()}`,
      payload: values.agreeToMechanics,
    })
    if (values.orderingFor === "Dependent") {
      await dispatch({
        type: "SAVE_BENEFICIARY_DETAILS",
        payload: {
          beneficiaryFirstName: values.beneficiaryFirstName,
          beneficiaryLastName: values.beneficiaryLastName,
          beneficiaryRelationship: values.beneficiaryRelationship,
        },
      })
    }
    let prevPath = location.pathname
    navigate("/flexmed/benefit-type", { state: { prevPath } })
  }

  const handleOnChange = async values => {
    if (values.target.value === "Myself") {
      await dispatch({
        type: "SAVE_BENEFICIARY_DETAILS",
        payload: {},
      })
    }
  }

  return (
    <Container isCentered desktop={6}>
      <div className="content">
        <Formik
          initialValues={{
            orderingFor: state[flow].orderingFor,
            agreeToMechanics: state[flow].agreeToMechanics,
            beneficiaryFirstName:
              state[flow]?.beneficiaryDetails?.beneficiaryFirstName,
            beneficiaryLastName:
              state[flow]?.beneficiaryDetails?.beneficiaryLastName,
            beneficiaryRelationship:
              state[flow]?.beneficiaryDetails?.beneficiaryRelationship,
          }}
          onSubmit={handleOnSubmit}
          validationSchema={mechanicsValidationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Section title="Program Mechanics">
                <ProgramMechanics flow={flow} />
              </Section>
              <Section title="Who are you filing reimbursement for?">
                <Fragment>
                  <div className="columns">
                    {options?.map(option => (
                      <div className="column">
                        <FormStyledRadio
                          name="orderingFor"
                          value={option?.label}
                          isActive={option?.label === values.orderingFor}
                          logo={DATA[option?.logo]?.childImageSharp.fixed}
                          onClick={() => {
                            setFieldValue("orderingFor", option?.label)
                            dispatch({
                              type: "SAVE_ORDERING_FOR",
                              payload: { orderingFor: option?.label },
                            })
                          }}
                        >
                          {option?.label}
                        </FormStyledRadio>
                      </div>
                    ))}
                  </div>
                </Fragment>
              </Section>
              {values.orderingFor == "Dependent" && (
                <BeneficiaryDetails
                  values={values}
                  setFieldValue={setFieldValue}
                  location={location}
                />
              )}
              <div class="has-text-centered has-text-weight-medium">
                <FormCheckbox
                  name="agreeToMechanics"
                  values={values.agreeToMechanics}
                  options={[agreeToMechanicsBlurb]}
                />
              </div>
              <ActionButtons
                submit={{
                  label: "Get Started",
                  disabled: isDisabled(values),
                  link: nextRoute,
                }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}

export default Mechanics
